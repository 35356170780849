import { isEmpty } from 'lodash'

export const SYSTEM_TYPE = {
  SHOP: 'shop',
  LIVE_MALL_CLUB: 'livemallclub',
}

export type TSystemType = (typeof SYSTEM_TYPE)[keyof typeof SYSTEM_TYPE]

export const isLiveMallClub = !isEmpty(process) && !isEmpty(process.env?.SYSTEM_TYPE) && process.env.SYSTEM_TYPE === SYSTEM_TYPE.LIVE_MALL_CLUB
