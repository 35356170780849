const SYSTEM_TYPE = {
  SHOP: 'shop',
  LIVE_MALL_CLUB: 'livemallclub',
}

const LOCALE_PATH = {
  [SYSTEM_TYPE.SHOP]: 'public/locales',
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: 'public/livemallclubLocales',
}

module.exports = { SYSTEM_TYPE, LOCALE_PATH }
