import type { ReactNode } from 'react'
import classnames from 'classnames/bind'

// Libs

// Components
import SimpleHeader from './components/SimpleHeader'

// Constants

// Context

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

type BasicLayoutProps = {
  children: ReactNode
}

const LiveMallLayout = (props: BasicLayoutProps) => {
  const { children } = props

  return (
    <div id='layout' className={cx('layout')}>
      <SimpleHeader />

      <div className={cx('layout-body')}>{children}</div>
    </div>
  )
}

export default LiveMallLayout
