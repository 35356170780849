const { SYSTEM_TYPE } = require('./systemConfig')

export const basePath = ''
export const LIVE_MALL_CLUB_ROUTE_PATH = {
  HOME: '/live', // 現在 / 給 /live 用，所以導頁時直接導 /live，next config 也有 redirect / -> /live
  LIVE: '/live',
  MALL: '/mall',
  PRODUCT: '/product',
  PRODUCT_DETAIL: '/product/[id]',
  USER: '/user',
  USER_DETAIL: '/user/[id]',
  SHOPPINGCART: '/shopping-cart',
  LIVE_ROOM: '/live/[roomId]',
  STRAWBERRY_LIVE_ROOM: '/strawberry/live/[roomId]', // 草莓網直播間
  STRAWBERRY_LIVE: '/strawberry/live', // 草莓網直播間
  PCHOME_LIVE_ROOM: '/pchome/live/[roomId]', // PCHOME直播間
  PCHOME_LIVE: '/pchome/live', // PCHOME直播間
  BRAND_LIVE_ROOM: '/[brandName]/live/[roomId]', // 品牌直播間 (brandName 是動態的，定義在 @/src/constants/brand.ts)
  LIVE_ROOM_PRODUCT: '/live/product', // 獨立的直播間商品列表，主要給 app 用
  REDIRECT: 'da3bec811f9b',
  ORDER_RESULT: '/order-result',
  ORDERS: '/orders',
  ME: '/me',
  ME_ACCOUNT: '/me/account',
  ME_ACCOUNT_AVATAR: '/me/account/avatar',
  ME_ADDRESS: '/me/address',
  ME_PHONE_CARRIER: '/me/phone-carrier',
  ME_COMPANY_UNIFORM_INVOICE: '/me/company-uniform-invoice',
  ME_COUPON: '/me/coupon',
  MY_LIVE_SHOPLIST: '/my-live/shoplist',
  MY_LIVE_FUTURE: '/my-live/future',
  MY_LIVE_PAST: '/my-live/past',
  SEARCH: '/search',
  SEARCH_MERCHANT: '/search/merchant',
  SEARCH_LIVE: '/search/live',
  ANALYSIS: '13df2c93a0b7',
  SEARCH_APP: '/search/app',
  EVENT_DETAIL: '/event/[id]',
  EVENT: '/event',
  NOT_FOUND: '/404',
  CALENDAR: '/calendar',
  EXPLORE: '/explore',
}

export const SHOP_ROUTE_PATH = {
  HOME: '/live', // 現在 / 給 /live 用，所以導頁時直接導 /live，next config 也有 redirect / -> /live
  LIVE: '/live',
  MALL: '/mall',
  PRODUCT: '/product',
  PRODUCT_DETAIL: '/product/[id]',
  USER: '/user',
  USER_DETAIL: '/user/[id]',
  SHOPPINGCART: '/shopping-cart',
  LIVE_ROOM: '/live/[roomId]',
  STRAWBERRY_LIVE_ROOM: '/strawberry/live/[roomId]', // 草莓網直播間
  STRAWBERRY_LIVE: '/strawberry/live', // 草莓網直播間
  PCHOME_LIVE_ROOM: '/pchome/live/[roomId]', // PCHOME直播間
  PCHOME_LIVE: '/pchome/live', // PCHOME直播間
  BRAND_LIVE_ROOM: '/[brandName]/live/[roomId]', // 品牌直播間 (brandName 是動態的，定義在 @/src/constants/brand.ts)
  LIVE_ROOM_PRODUCT: '/live/product', // 獨立的直播間商品列表，主要給 app 用
  REDIRECT: 'da3bec811f9b',
  ORDER_RESULT: '/order-result',
  ORDERS: '/orders',
  ME: '/me',
  ME_ACCOUNT: '/me/account',
  ME_ACCOUNT_AVATAR: '/me/account/avatar',
  ME_ADDRESS: '/me/address',
  ME_PHONE_CARRIER: '/me/phone-carrier',
  ME_COMPANY_UNIFORM_INVOICE: '/me/company-uniform-invoice',
  ME_COUPON: '/me/coupon',
  MY_LIVE_SHOPLIST: '/my-live/shoplist',
  MY_LIVE_FUTURE: '/my-live/future',
  MY_LIVE_PAST: '/my-live/past',
  SEARCH: '/search',
  SEARCH_MERCHANT: '/search/merchant',
  SEARCH_LIVE: '/search/live',
  ANALYSIS: '13df2c93a0b7',
  SEARCH_APP: '/search/app',
  EVENT_DETAIL: '/event/[id]',
  EVENT: '/event',
  NOT_FOUND: '/404',
  CALENDAR: '/calendar',
}

const ROUTE_PATH_DATA: any = {
  [SYSTEM_TYPE.SHOP]: SHOP_ROUTE_PATH,
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: LIVE_MALL_CLUB_ROUTE_PATH,
}

export const ROUTE_PATH = ROUTE_PATH_DATA[`${process.env.SYSTEM_TYPE}`]

const ROUTE_PATH_FOR_FORBIDDEN_PAGE_DATA: any = {
  [SYSTEM_TYPE.SHOP]: {
    EXPLORE: '/explore',
  },
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: {},
}

export const ROUTE_PATH_FOR_FORBIDDEN_PAGE = ROUTE_PATH_FOR_FORBIDDEN_PAGE_DATA[`${process.env.SYSTEM_TYPE}`]

export const API_PATH_DATA: any = {
  [SYSTEM_TYPE.SHOP]: {
    PAYMENT_REDIRECT: '/v1/payment/redirect',
    LOGISTICS_GET_MAP_REDIRECT: '/v1/logistics/get-map/c2c-store',
  },
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: {
    PAYMENT_REDIRECT: '/v1/payment/redirect',
    LOGISTICS_GET_MAP_REDIRECT: '/v1/logistics/get-map/c2c-store',
  },
}

export const API_PATH = API_PATH_DATA[`${process.env.SYSTEM_TYPE}`]
