// components
import BasicLayout from '@/components/layouts/BasicLayout'
import LiveMallLayout from '@/components/layouts/LiveMallLayout'
// Libs
// const
import { BRAND_LAYOUT, VALID_BRAND_NAME } from '@/constants/brand'
import { SYSTEM_TYPE, TSystemType } from '@/constants/systemType'
const SYSTEM_TYPE_LAYOUT = {
  [SYSTEM_TYPE.SHOP]: BasicLayout,
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: LiveMallLayout,
}

export const useAppLayout = ({ brandName }: { brandName?: string }) => {
  const validBrandName = Object.values(VALID_BRAND_NAME).find((name) => name === brandName)
  const systemType = process.env.SYSTEM_TYPE as TSystemType

  // 有 brand 的話 brand 優先，因為有可能，因為有可能在同系統出現不同 brand
  if (validBrandName) {
    return BRAND_LAYOUT[validBrandName]
  }

  // 必定會有 SYSTEM_TYPE

  const Layout = SYSTEM_TYPE_LAYOUT[systemType] || BasicLayout

  return Layout
}
