const { SYSTEM_TYPE } = require('./systemConfig')

// 順序看 LOCALE!!! LANGUAGE_LIST 是用因為 Object.values(LOCALE)
const LOCALE_DATA = {
  [SYSTEM_TYPE.SHOP]: {
    ZH_TW: 'zh-tw',
    ZH_HK: 'zh-hk',
    EN_US: 'en-us',
    EN_HK: 'en-hk',
    KO_KR: 'ko-kr',
    // EN_HK: 'en-hk',
    // TR_TR: 'tr-tr',
    // ID_ID: 'id-id',
    // ES_PE: 'es-pe',
    // ES_ES: 'es-es',
    // IT_IT: 'it-it',
    // JA_JP: 'ja-jp',
    // PT_BR: 'pt-br',
    SA_IN: 'sa-in', // 測試用，value 故意給語言包的 key
  },
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: {
    // ZH_TW: 'zh-tw',
    // ZH_HK: 'zh-hk',
    EN_US: 'en-us',
    // EN_HK: 'en-hk',
    // KO_KR: 'ko-kr',
    // EN_HK: 'en-hk',
    // TR_TR: 'tr-tr',
    // ID_ID: 'id-id',
    // ES_PE: 'es-pe',
    // ES_ES: 'es-es',
    // IT_IT: 'it-it',
    // JA_JP: 'ja-jp',
    // PT_BR: 'pt-br',
    // SA_IN: 'sa-in', // 測試用，value 故意給語言包的 key
  },
}

const LOCALE = LOCALE_DATA[process.env.SYSTEM_TYPE]

// 給 moment 的 locale 用
const LOCALE_CODE_DATA = {
  [SYSTEM_TYPE.SHOP]: {
    [LOCALE.ZH_TW]: 'zh-tw',
    // [LOCALE.EN_US]: 'en',
    [LOCALE.ZH_HK]: 'en-gb',
    [LOCALE.EN_HK]: 'en-hk',
    [LOCALE.KO_KR]: 'ko-kr',
    // [LOCALE.EN_HK]: 'en-gb',
    // [LOCALE.TR_TR]: 'tr',
    // [LOCALE.ID_ID]: 'id',
    // [LOCALE.ES_PE]: 'es',
    // [LOCALE.ES_ES]: 'es',
    // [LOCALE.IT_IT]: 'it',
    // [LOCALE.JA_JP]: 'ja',
    // [LOCALE.PT_BR]: 'pt',
    [LOCALE.SA_IN]: 'zh-tw', // 測試用，先給 zh-tw
  },
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: {
    // [LOCALE.ZH_TW]: 'zh-tw',
    [LOCALE.EN_US]: 'en-us',
    // [LOCALE.ZH_HK]: 'en-gb',
    // [LOCALE.EN_HK]: 'en-hk',
    // [LOCALE.KO_KR]: 'ko-kr',
    // [LOCALE.EN_HK]: 'en-gb',
    // [LOCALE.TR_TR]: 'tr',
    // [LOCALE.ID_ID]: 'id',
    // [LOCALE.ES_PE]: 'es',
    // [LOCALE.ES_ES]: 'es',
    // [LOCALE.IT_IT]: 'it',
    // [LOCALE.JA_JP]: 'ja',
    // [LOCALE.PT_BR]: 'pt',
    // [LOCALE.SA_IN]: 'zh-tw', // 測試用，先給 zh-tw
  },
}

const LOCALE_CODE = LOCALE_CODE_DATA[process.env.SYSTEM_TYPE]

const LOCALE_LABEL_DATA = {
  [SYSTEM_TYPE.SHOP]: {
    [LOCALE.ZH_TW]: '繁體中文',
    [LOCALE.ZH_HK]: '繁體中文 (香港)',
    [LOCALE.EN_US]: 'English',
    [LOCALE.EN_HK]: 'English (Hong Kong)',
    [LOCALE.KO_KR]: 'English ( Korea )',
    // [LOCALE.EN_HK]: 'English (Hong Kong)',
    // [LOCALE.TR_TR]: 'Türkçe',
    // [LOCALE.ID_ID]: 'Bahasa Indonesia',
  },
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: {
    // [LOCALE.ZH_TW]: '繁體中文',
    // [LOCALE.ZH_HK]: '繁體中文 (香港)',
    [LOCALE.EN_US]: 'English',
    // [LOCALE.EN_HK]: 'English (Hong Kong)',
    // [LOCALE.KO_KR]: 'English ( Korea )',
    // [LOCALE.EN_HK]: 'English (Hong Kong)',
    // [LOCALE.TR_TR]: 'Türkçe',
    // [LOCALE.ID_ID]: 'Bahasa Indonesia',
  },
}

const LOCALE_LABEL = LOCALE_LABEL_DATA[process.env.SYSTEM_TYPE]

// const chineseLocale = [LOCALE.ZH_TW, LOCALE.ZH_HK]
const chineseLocale = ['zh-tw']

const LANGUAGE_LIST = Object.values(LOCALE)
  .map((item) => {
    return {
      label: LOCALE_LABEL[item],
      value: item,
    }
  })
  .filter((item) => item.label && item.label.length > 0)

const DEFAULT_LOCALE = LOCALE.EN_US

// 目前有支援的語系 // default 是給根用的
const allowLocaleData = {
  [SYSTEM_TYPE.SHOP]: [
    'default',
    LOCALE.ZH_TW,
    LOCALE.EN_US,
    LOCALE.ZH_HK,
    LOCALE.EN_HK,
    LOCALE.KO_KR,
    // LOCALE.EN_HK,
    // LOCALE.TR_TR,
    // LOCALE.KO_KR,
    // LOCALE.ID_ID,
    // LOCALE.ES_PE,
    // LOCALE.ES_ES,
    // LOCALE.IT_IT,
    // LOCALE.JA_JP,
    // LOCALE.PT_BR,
    LOCALE.SA_IN,
  ],
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: [
    'default',
    // LOCALE.ZH_TW,
    LOCALE.EN_US,
    // LOCALE.ZH_HK,
    // LOCALE.EN_HK,
    // LOCALE.KO_KR,
    // LOCALE.EN_HK,
    // LOCALE.TR_TR,
    // LOCALE.KO_KR,
    // LOCALE.ID_ID,
    // LOCALE.ES_PE,
    // LOCALE.ES_ES,
    // LOCALE.IT_IT,
    // LOCALE.JA_JP,
    // LOCALE.PT_BR,
    // LOCALE.SA_IN,
  ],
}

const allowLocale = allowLocaleData[process.env.SYSTEM_TYPE]

// 因為 next.config 也要用，所以只能用 es5 寫法
module.exports = { DEFAULT_LOCALE, allowLocale, LOCALE, LOCALE_LABEL, LANGUAGE_LIST, LOCALE_CODE, chineseLocale }
