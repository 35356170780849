import { useRouter } from 'next/router'

// Libs
import { useAppSelector } from '@/redux/hooks'

const useI18n = () => {
  const { locale } = useRouter()

  const isChinese = locale === 'zh-tw' || locale === 'zh-hk'

  const isOverseaUser = useAppSelector((state) => state.common.isOverseaUser)

  return { isOverseaUser, isChinese }
}

export default useI18n
