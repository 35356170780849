import { SYSTEM_TYPE, TSystemType } from '@/constants/systemType'

// Staging => stage
// Production => 正式 / 封測

// 目前環境變數從 docker 餵 DEPLOY_ENV
// Staging => stage
// Production => 正式 / 封測
// 因為前端沒有 DEV 環境所以 DEV 變數自己另外給

export const CURRENT_SYSTEM_TYPE = process.env.SYSTEM_TYPE

export const ENVIORNMENT = {
  STAGE: 'Staging',
  PROD: 'Production',
  PRE: 'Pre',
  DEV: 'development',
}

export const configs = {
  [SYSTEM_TYPE.SHOP]: {
    [ENVIORNMENT.DEV]: {
      domainUrl: 'https://dev-www.tutorabcshop.com',
      apiUrl: 'https://dev-api.tutorabcshop.com/api',
      logInUrl: 'http://stage-passport.tutorabcshop.com/api/url/login/1/tw',
      registerUrl: 'http://stage-passport.tutorabcshop.com/api/url/regist/1/tw',
      logOutUrl: 'http://stage-passport.tutorabcshop.com/api/logout/1',
      dittoApiUrl: 'https://dittostage.tutorabc.com/integrate',
    },
    [ENVIORNMENT.STAGE]: {
      domainUrl: 'https://stage-www.tutorabcshop.com',
      apiUrl: 'https://stage-api.tutorabcshop.com/api',
      logInUrl: 'http://stage-passport.tutorabcshop.com/api/url/login/1/tw',
      registerUrl: 'http://stage-passport.tutorabcshop.com/api/url/regist/1/tw',
      logOutUrl: 'http://stage-passport.tutorabcshop.com/api/logout/1',
      dittoApiUrl: 'https://dittostage.tutorabc.com/integrate',
    },
    [ENVIORNMENT.PRE]: {
      domainUrl: 'https://wwwp.tutorabcshop.com',
      apiUrl: 'https://apip.tutorabcshop.com/api',
      logInUrl: 'https://pre-passport.tutorabcshop.com/api/url/login/1/tw',
      registerUrl: 'https://pre-passport.tutorabcshop.com/api/url/regist/1/tw',
      logOutUrl: 'https://pre-passport.tutorabcshop.com/api/logout/1',
      dittoApiUrl: 'https://ditto-apip.tutorabc.com/integrate',
    },
    [ENVIORNMENT.PROD]: {
      domainUrl: 'https://www.tutorabcshop.com',
      apiUrl: 'https://api.tutorabcshop.com/api',
      logInUrl: 'https://passport.tutorabcshop.com/api/url/login/1/tw',
      registerUrl: 'https://passport.tutorabcshop.com/api/url/regist/1/tw',
      logOutUrl: 'https://passport.tutorabcshop.com/api/logout/1',
      dittoApiUrl: 'https://ditto-api.tutorabc.com/integrate',
    },
  },
  [SYSTEM_TYPE.LIVE_MALL_CLUB]: {
    [ENVIORNMENT.DEV]: {
      domainUrl: 'http://wwwj.tutorabcshop.com:8001',
      apiUrl: 'https://apip.tutorabcshop.com/api',
      logInUrl: 'http://stage-passport.tutorabcshop.com/api/url/login/1/tw',
      registerUrl: 'http://stage-passport.tutorabcshop.com/api/url/regist/1/tw',
      logOutUrl: 'http://stage-passport.tutorabcshop.com/api/logout/1',
      dittoApiUrl: 'https://dittostage.tutorabc.com/integrate',
    },
    [ENVIORNMENT.STAGE]: {
      domainUrl: 'https://stage-www.tutorabcshop.com',
      apiUrl: 'https://stage-api.tutorabcshop.com/api',
      logInUrl: 'http://stage-passport.tutorabcshop.com/api/url/login/1/tw',
      registerUrl: 'http://stage-passport.tutorabcshop.com/api/url/regist/1/tw',
      logOutUrl: 'http://stage-passport.tutorabcshop.com/api/logout/1',
      dittoApiUrl: 'https://dittostage.tutorabc.com/integrate',
    },
    [ENVIORNMENT.PRE]: {
      domainUrl: 'https://wwwp.tutorabcshop.com',
      apiUrl: 'https://apip.tutorabcshop.com/api',
      logInUrl: 'https://pre-passport.tutorabcshop.com/api/url/login/1/tw',
      registerUrl: 'https://pre-passport.tutorabcshop.com/api/url/regist/1/tw',
      logOutUrl: 'https://pre-passport.tutorabcshop.com/api/logout/1',
      dittoApiUrl: 'https://ditto-apip.tutorabc.com/integrate',
    },
    [ENVIORNMENT.PROD]: {
      domainUrl: 'https://www.tutorabcshop.com',
      apiUrl: 'https://api.tutorabcshop.com/api',
      logInUrl: 'https://passport.tutorabcshop.com/api/url/login/1/tw',
      registerUrl: 'https://passport.tutorabcshop.com/api/url/regist/1/tw',
      logOutUrl: 'https://passport.tutorabcshop.com/api/logout/1',
      dittoApiUrl: 'https://ditto-api.tutorabc.com/integrate',
    },
  },
}
//AIzaSyA4TdgEHgRYVwdpHt42PaEteVjXLIPOtaA
export const googleCalendarConfig = {
  //57038433915-ahmfm8e90bh1ue1h6m968mtnpeehkrn8.apps.googleusercontent.com
  clientId: '57038433915-ahmfm8e90bh1ue1h6m968mtnpeehkrn8.apps.googleusercontent.com', // OAuth 2.0 用戶端 ID
  apiKey: 'AIzaSyA4TdgEHgRYVwdpHt42PaEteVjXLIPOtaA', // API金鑰 or API Key
  scope: 'https://www.googleapis.com/auth/calendar',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
}

declare const process: {
  browser: boolean
  env: {
    DEPLOY_ENV: string
    SYSTEM_TYPE: TSystemType
  }
}

export const envConfigs = configs[CURRENT_SYSTEM_TYPE][process.env.DEPLOY_ENV]
