import { useEffect } from 'react'
import { TValidBrandName, VALID_BRAND_NAME } from '@/constants/brand'
import { CURRENT_SYSTEM_TYPE } from '@/constants/config'

export const useAppTheme = ({ brandName }: { brandName?: TValidBrandName }) => {
  useEffect(() => {
    //  Brand 優先，沒 Brand 看系統
    const appEl = window.document.getElementById('app')
    const validBrandName = Object.values(VALID_BRAND_NAME).find((name) => name === brandName)
    if (validBrandName) {
      if (appEl && validBrandName) {
        appEl.setAttribute('data-theme', validBrandName)
      }
    } else {
      if (appEl) {
        appEl.setAttribute('data-theme', `${CURRENT_SYSTEM_TYPE}`)
      }
    }
  }, [brandName, CURRENT_SYSTEM_TYPE])
}
